<template>
  <div>
  <el-card  v-for="job in jobList"
  :key="job.id">
    <el-row  :gutter="10">
      <el-col :span="6" class="left-container-border">
        <svg style="margin: 0px auto;" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="18" :fill="statusColors(job).bcc"/>
          <path d="M26.3574 25.2773C26.3574 25.2773 24.6984 24.115 24.5363 24.046L26.5884 18.4518C26.7437 18.1931 26.6367 17.5619 25.94 17.5171H23.2809V12.5574C23.2809 12.3056 23.136 12.0539 22.8842 11.9469L18.7178 9.82581C18.5385 9.75338 18.2867 9.75338 18.1074 9.82581L13.5443 11.9469C13.2925 12.0539 13.1477 12.3056 13.1477 12.5954V17.5205H10.454C9.69868 17.5964 9.73662 18.2379 9.8056 18.4552L11.8888 24.1219C11.7267 24.1944 10.0677 25.4256 10.0677 25.4256C9.74352 25.6395 9.70903 26.0706 9.92287 26.3948C10.0677 26.5742 10.4368 26.8535 10.9265 26.5397L12.2889 25.4981C13.2684 24.9048 14.0479 25.5705 14.0479 25.5705C14.9791 26.2534 16.4518 26.2534 17.3865 25.5705C17.3865 25.5705 18.1211 24.9669 19.0731 25.536C20.0043 26.2189 21.477 26.2189 22.4117 25.536C22.4117 25.536 23.1705 24.829 24.2052 25.4291L25.5331 26.3983C25.8193 26.6121 26.2849 26.5776 26.5022 26.2534C26.7161 25.9602 26.6781 25.4946 26.3574 25.2773ZM18.9351 11.54L21.8461 13.0265V17.4826H18.9351V11.54ZM14.548 13.061L17.4934 11.6848V17.4826H14.548V13.061ZM21.5495 24.4185C21.1183 24.7427 20.3285 24.7427 19.8974 24.4185C18.9662 23.7356 17.5969 23.7253 16.5587 24.4185C16.5174 24.4461 15.662 25.0463 14.7653 24.384C14.3514 24.0805 13.834 23.9115 13.3029 23.877L11.4956 18.9174H24.9019L23.1084 23.8081C22.1737 23.8943 21.5495 24.4185 21.5495 24.4185Z" :fill="statusColors(job).text"/>
        </svg>
        <div>
          <span class="mid-font"> {{JOB_TYPE[job.type].filterText}}</span>
        </div>
        <div>
          <span class="mid-font" :style="{color:statusColors(job).text}"> {{STATUS_SELECTION[job.status].title}}</span>
        </div>
      </el-col>
      <el-col :span="15"  style="padding-left:8px;">
        <el-row style="text-align: left; padding-bottom: 3px;">
          <span class="icon-svg"><img alt="img" src="@/assets/images/mobile/calendar.svg" /> </span>
          <span class="small-font">{{job?.vesselEta?$moment(job?.vesselEta).format('DD-MM-YYYY  HH:mm:ss') :'-'}}</span>
        </el-row>
        <el-row style="text-align: left; padding-bottom: 3px;">
          <span class="icon-svg"><img alt="img" src="@/assets/images/mobile/vessel.svg" />
        </span>
        <span v-if="job?.type==2" class="small-font ">{{job.locationLevel1 ? job.locationLevel1 : '-'}}</span>
        <span e-else class="small-font ">{{job.vesselName ? `${job.vesselName} / ${job.vesselImo}` :'-'}}</span>
        </el-row>
        <el-row style="text-align: left; padding-bottom: 3px;">
          <span class="icon-svg"><img alt="img" src="@/assets/images/mobile/location.svg" />
          </span>

          <span  v-if="job?.type==2" class="small-font">{{job?.floaterName?job.floaterName :( job.locationLevel1 ? `${job.locationLevel1} / ${job.locationLevel2}` : '_')}}</span>
          <span e-else class="small-font">{{ job.locationLevel1 ? `${job.locationLevel1} / ${job.locationLevel2??'-'}`:'-' }}</span>
        </el-row>
        <el-row style="text-align: left; padding-bottom: 3px;">
          <span class="icon-svg"> <img alt="img" src="@/assets/images/mobile/oil.svg" /> </span>
          <span class="small-font">{{job.productName}}{{job.finalQty ? ` / ${job.finalQty} ${job.qtyUnitMetric}` : job.loadingQty && ` / ${job.loadingQty} ${job.qtyUnitMetric}`}}</span>
        </el-row>
      </el-col>
      <el-col :span="1"  style="text-align: right"  v-if="hasTableMenu">
        <div>
          <el-dropdown trigger="click" @command="handleCommand($event,job)">
            <i class="el-icon-more"/>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
              v-for="item in actionList"
              :key="item.key"
              :icon="item.icon"
              :command="item.key">{{item.label}}</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- <template>
          <dropdown-action-menu
            :actionList="$emit('getJobTableMenuList',job)"
            :propsData="job"
            @handleDropdownMenuClick="handleDropdownMenuClick"
          />
        </template> -->
      </el-col>
    </el-row>
  </el-card>
  </div>
</template>
<script>

export default {
  name: 'JobCardList',
  props:{
    hasTableMenu:Boolean,
    jobList:Array,
    actionList:Array,

  },
  data () {

const STATUS_SELECTION = {
  1: {
    title: 'Pending',
    className: 'pending'
  },
  2: {
    title: 'Accepted',
    className: 'accepted'
  },
  3: {
    title: 'In Progress',
    className: 'in-progress'
  },
  4: {
    title: 'Completed',
    className: 'complete'
  },
  5: {
    title: 'Cancelled',
    className: 'cancelled'
  }
}
const JOB_TYPE = {
  0: { title: 'All Job Type', description: 'All Job Type', filterText: 'All Job Type', value: undefined },
  1: { title: 'Delivery', description: 'Delivery Schedule', filterText: 'Delivery', value: 1 },
  2: { title: 'Loading', description: 'Loading Schedule', filterText: 'Loading', value: 2 },
  3: { title: 'Ship to Ship Transfer', description: 'Ship to Ship Transfer Schedule', filterText: 'STS', value: 3 }
}
    return {
      STATUS_SELECTION,
      JOB_TYPE,
      actionListing: [
        {
          key:'acptJob',
          title:'Acpt Job',
          action:() => {}
        }
      ],
    }
  },

  mounted () {
    this.getInitState()
  },

  methods: {
    getInitState () {

    },

    statusColors (job) {
      let _statusColors = {
        bcc: '#F0C9C1',
        text: '#F60000'
      }
      switch (job.status) {
        case 1:
          _statusColors = {
            bcc: '#F2FBFF',
            text: '#11B0FF'
          }
          break
        case 2:
          _statusColors = {
            bcc: '#FFF5F3',
            text: '#F5684A'
          }
          break
        case 3:
          _statusColors = {
            bcc: '#D4FFF2',
            text: '#149770'
          }
          break
        case 4:
          _statusColors = {
            bcc: '#F5EFFD',
            text: '#6539FF'
          }
          break
        default:
          break
      }
      return { ..._statusColors }
    },

    handleCommand (action,job) {
      this.$emit('handleJobDropdownMenuClick', action, job)
    }
  },
  computed: {

  }
}
</script>
<style lang="scss" scoped>
  .el-card{
    margin-bottom: 5px;
  }
  /deep/.el-card__body {
    padding: 10px 0px;
  }
  .left-container-border{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    border-right: 1px dashed #B9C9DD;
  }
  span.mid-font{
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  span.small-font{
    font-size: 11px;
    font-weight: 400;
  }

  .justify-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .icon-svg{
    display: inline-block;
    width: 15px;
    margin: 0 auto
  }
  .more-icon-container{
    display: inline-block;
    width: 20px;
    height: 40px;
  }
  .el-icon-more {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

</style>
